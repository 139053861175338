import React from "react";

const GamesBanterIcon = ({ width = "20", height = "21", fill = "white" }) => (
  <span>
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <style>
          {`.cls-1 {
            fill: none;
            stroke: ${fill};
            stroke-miterlimit: 10;
            stroke-width: 1.5px;
          }`}
        </style>
      </defs>

      <path
        className="cls-1"
        d="M20.376,2.987h-10.233v2.683c0,.275.214.498.477.498h3.62c.263,0,.477.223.477.498v4.051
           c0,.274-.215.498-.477.498h-3.62c-.263,0-.477.223-.477.498v3.783c0,.275-.214.498-.477.498h-3.876
           c-.262,0-.477-.224-.477-.498v-3.783c0-.275-.214-.498-.477-.498h-2.526v4.668c0,1.593,1.291,2.884,2.884,2.884h6.913
           l6.235,3.93c.426.384,1.105.082,1.105-.491v-3.439h.929c1.593,0,2.884-1.291,2.884-2.884V5.871
           c0-1.593-1.291-2.884-2.884-2.884Z"
      />
      <path
        className="cls-1"
        d="M5.312,5.67v-2.683h-.299c-1.494,0-2.704,1.211-2.704,2.704v.477h2.526
           c.263,0,.477-.223.477-.498Z"
      />
      <path
        className="cls-1"
        d="M14.239,6.168h-3.62c-.263,0-.477-.223-.477-.498V1.887c0-.275-.214-.499-.477-.499h-3.876
           c-.263,0-.477.223-.477.499v3.783c0,.275-.214.498-.477.498H1.215c-.262,0-.477.224-.477.499v4.051
           c0,.275.214.498.477.498h3.62c.263,0,.477.223.477.499v3.783c0,.274.215.499.477.499h3.876
           c.263,0,.477-.223.477-.499v-3.783c0-.275.214-.499.477-.499h3.62c.262,0,.477-.224.477-.498v-4.051
           c0-.275-.214-.499-.477-.499Z"
      />
    </svg>
  </span>
);

export default GamesBanterIcon;
