import React from "react";

const GamesPupQuizIcon = ({ width = "20", height = "21", fill = "white" }) => (
  <span>
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9838 15.846C14.2317 15.5043 14.4499 15.2495 14.6331 15.0907C16.292 13.6474 19.0677 11.2315 18.9992 7.61296C18.9873 3.96665 15.8475 1 12.0002 1C8.15302 1 5.00049 3.97565 5.00049 7.63395C5.00049 8.91158 6.03321 9.95017 7.30271 9.95017C8.57221 9.95017 9.6042 8.91083 9.6042 7.63395C9.6042 6.53091 10.6786 5.6332 11.9995 5.6332C13.3204 5.6332 14.3948 6.53091 14.3948 7.63395V7.68565C14.4276 9.14462 12.8059 10.5564 11.6212 11.5867C10.9474 12.1727 10.415 12.6366 10.0435 13.1971C9.10756 14.6088 9.11278 16.2889 9.19393 16.8172C9.20361 16.8794 9.21627 16.9401 9.23042 17C9.85958 16.477 10.666 16.163 11.5453 16.163C12.3547 16.163 13.0992 16.432 13.7031 16.8824C13.7195 16.7647 13.7254 16.6568 13.7299 16.5646C13.7411 16.3196 13.7478 16.1712 13.9838 15.846Z"
        stroke={fill}
        strokeWidth="1.53"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5799 16.7651C10.0252 16.7651 8.76486 18.0254 8.76486 19.5801C8.76486 21.1348 10.0252 22.3951 11.5799 22.3951C13.1345 22.3951 14.3949 21.1348 14.3949 19.5801C14.3949 18.0254 13.1345 16.7651 11.5799 16.7651ZM7.23486 19.5801C7.23486 17.1804 9.18019 15.2351 11.5799 15.2351C13.9795 15.2351 15.9249 17.1804 15.9249 19.5801C15.9249 21.9798 13.9795 23.9251 11.5799 23.9251C9.18019 23.9251 7.23486 21.9798 7.23486 19.5801Z"
        fill={fill}
      />
    </svg>
  </span>
);

export default GamesPupQuizIcon;
