import React from "react";

const GamesVEGconnectIcon = ({
  width = "20",
  height = "21",
  fill = "white",
}) => (
  <span>
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_775_1373)">
        <path
          d="M7.06103 4.50494H10.085V0.741943H0.676025V10.1499H4.44003V7.12694C4.44003 5.67894 5.61403 4.50494 7.06103 4.50494Z"
          stroke={fill}
          strokeWidth="1.53"
          strokeMiterlimit="10"
        />
        <path
          d="M19.495 7.12594V10.1489H23.259V0.741943H13.85V4.50594H16.874C18.322 4.50594 19.495 5.67894 19.495 7.12594Z"
          stroke={fill}
          strokeWidth="1.53"
          strokeMiterlimit="10"
        />
        <path
          d="M4.44003 16.939V13.916H0.676025V23.324H10.085V19.56H7.06103C5.61303 19.56 4.44003 18.386 4.44003 16.939Z"
          stroke={fill}
          strokeWidth="1.53"
          strokeMiterlimit="10"
        />
        <path
          d="M16.874 19.56H13.85V23.324H23.259V13.916H19.495V16.939C19.495 18.387 18.321 19.56 16.874 19.56Z"
          stroke={fill}
          strokeWidth="1.53"
          strokeMiterlimit="10"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.67493 7.12587C3.67493 5.25656 5.19024 3.73987 7.06093 3.73987H10.0849H13.8491H16.8731C18.7424 3.73987 20.2591 5.25518 20.2591 7.12587V10.1489V13.916V16.939C20.2591 18.8083 18.7438 20.325 16.8731 20.325H13.8491H10.0849H7.06093C5.19162 20.325 3.67493 18.8097 3.67493 16.939V13.916V10.1489V7.12587ZM7.06093 5.26987C6.03561 5.26987 5.20493 6.10118 5.20493 7.12587V10.1489V13.916V16.939C5.20493 17.9643 6.03623 18.795 7.06093 18.795H10.0849H13.8491H16.8731C17.8984 18.795 18.7291 17.9637 18.7291 16.939V13.916V10.1489V7.12587C18.7291 6.10056 17.8978 5.26987 16.8731 5.26987H13.8491H10.0849H7.06093Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_775_1373">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </span>
);

export default GamesVEGconnectIcon;
